import logo200Image from "assets/img/logo/logo_200.png";

import sidebarBgImage from "assets/img/sidebar/sidebar.png";

import AuthContext from "../../context/autenticacion/authContext";
import colegioContext from "../../context/colegios/colegioContext";
import GeneralContext from "../../context/general/generalContext";

import React, { useState, useContext, useEffect } from "react";
import {
  MdAccountCircle,
  MdKeyboardArrowDown,
  MdSend,
  MdHome,
  MdPersonOutline,
} from "react-icons/md";

import { FaSchool, FaExchangeAlt } from "react-icons/fa";

import { AiOutlineQrcode } from "react-icons/ai";

import { BsFillInboxesFill } from "react-icons/bs";

import { FiLogOut } from "react-icons/fi";
import { NavLink, Redirect } from "react-router-dom";
import {
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from "reactstrap";
import bn from "utils/bemnames";

function Sidebar(props) {
  // Extrar empresas de state inicial
  const colegiosContext = useContext(colegioContext);
  const { limpiarColegios, detallescolegioseleccionado } = colegiosContext;

  // Extraer la información de autenticación
  const authContext = useContext(AuthContext);
  const { cerrarSesion, usuarioAutenticado } = authContext;

  const generalContext = useContext(GeneralContext);
  const { resetDasboard, rutaActualGlobal, rutaactualglobal } = generalContext;

  const [isOpenS, setIsOpen] = useState({
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
    isOpenInventario: true,
  });

  const usuario = JSON.parse(localStorage.getItem("usuario"));

  const [casoUno, setCasoUno] = useState(false);
  const [casoDos, setCasoDos] = useState(false);
  const [casoTres, setCasoTres] = useState(false);

  const splitURLActual = window.location.href.split("/#/");
  var rutaActual = splitURLActual[1];

  useEffect(() => {
    if (usuario === null) return;
    if (usuario.is_admin && usuario.is_supervisor === false) {
      setCasoUno(true);
      setCasoDos(false);
      setCasoTres(false);
      return;
    } else if (usuario.is_admin === false && usuario.is_supervisor) {
      setCasoDos(true);
      setCasoUno(false);
      setCasoTres(false);
      return;
    } else if (usuario.is_admin === false && usuario.is_supervisor === false) {
      setCasoTres(true);
      setCasoUno(false);
      setCasoDos(false);
      return;
    }
    // eslint-disable-next-line
  }, [usuario]);

  const subMenuDashboard = [
    {
      to: "/dashboard",
      name: "Dashboard",
      exact: false,
      Icon: MdHome,
    },
  ];

  // INICIO SUB MENÚ ADMINISTRAR
  const subMenuAdministrar = [
    { to: "/usuarios", name: "Usuarios", exact: false, Icon: MdAccountCircle },

    {
      to: "/establecimientos",
      name: "Establecimientos",
      exact: false,
      Icon: FaSchool,
    },

    {
      to: "/codigos",
      name: "Codigos",
      exact: false,
      Icon: AiOutlineQrcode,
    },
  ];
  // FIN SUB MENÚ ADMINISTRAR

  // INICIO SUB MENÚ INVENTARIO SUPER ADMIN || REPRESENTANTE
  const subMenuInventario = [
    {
      to: "/inventario",
      name: "Inventario",
      exact: false,
      Icon: BsFillInboxesFill,
    },

    {
      to: "/prestamos",
      name: "Entrada / Salida",
      exact: false,
      Icon: FaExchangeAlt,
    },
  ];
  // FIN SUB MENÚ INVENTARIO

  // SUB MENÚ CUENTA
  const subMenuCerrarSesion = [
    {
      to: "/perfil",
      name: "Perfil",
      exact: false,
      Icon: MdPersonOutline,
    },
    {
      to: "/",
      name: "Cerrar Sesión",
      exact: false,
      Icon: FiLogOut,
    },
  ];
  // FIN SUB MENÚ CUENTA

  const bem = bn.create("sidebar");

  // Obtener empresas cuando carga el componente
  useEffect(() => {
    usuarioAutenticado();
    rutaActualGlobal(rutaActual)
    if (rutaActual !== "dashboard") {
      resetDasboard();
      return;
    }
  }, [rutaActual, rutaactualglobal]);

  const handleClick = (name) => () => {
    setIsOpen((prevState) => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  const terminarSesion = () => {
    cerrarSesion();
    limpiarColegios();
  };

  const sidebarBackground = {
    backgroundImage: `url("${sidebarBgImage}")`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <>
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e("background")} style={sidebarBackground} />
        <div className={bem.e("content")}>
          <Navbar className="navbar-brand d-flex">
            <img
              src={logo200Image}
              width="190"
              height="100"
              className="pr-2"
              alt=""
            />
          </Navbar>

          <Nav vertical>
            {/* -------------INICIO BOTON DASHBOARD --------------------- */}
            {casoUno || casoDos || casoTres
              ? subMenuDashboard.map(({ to, name, exact, Icon }, index) => (
                  <NavItem key={index} className={bem.e("nav-item")}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase font-weight-bold"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                      onClick={() => resetDasboard()}
                    >
                      <Icon className={bem.e("nav-item-icon")} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                ))
              : null}
            {/* ------------- FIN BOTON DASHBOARD --------------------- */}

            {/* -------------INICIO MENUS DEL SUPER ADMINISTRADOR ------------------ */}
            {usuario === null ? (
              <Redirect to="/" />
            ) : casoUno ? (
              <>
                <NavItem
                  className={bem.e("nav-item")}
                  onClick={handleClick("Components")}
                >
                  <BSNavLink
                    className={bem.e("nav-item-collapse")}
                    onClick={() => resetDasboard()}
                  >
                    <div className="d-flex">
                      <MdSend className={bem.e("nav-item-icon")} />
                      <span className=" align-self-start font-weight-bold">
                        Administrar
                      </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e("nav-item-icon")}
                      style={{
                        padding: 0,
                        transform: isOpenS.isOpenComponents
                          ? "rotate(0deg)"
                          : "rotate(-90deg)",
                        transitionDuration: "0.3s",
                        transitionProperty: "transform",
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                <Collapse isOpen={isOpenS.isOpenComponents}>
                  {subMenuAdministrar.map(
                    ({ to, name, exact, Icon }, index) => (
                      <NavItem key={index} className={bem.e("nav-item")}>
                        <BSNavLink
                          id={`navItem-${name}-${index}`}
                          className="font-italic"
                          tag={NavLink}
                          to={to}
                          activeClassName="active"
                          exact={exact}
                          onClick={() => resetDasboard()}
                        >
                          <Icon className={bem.e("nav-item-icon")} />
                          <span className="">{name}</span>
                        </BSNavLink>
                      </NavItem>
                    )
                  )}
                </Collapse>
              </>
            ) : null}

            {detallescolegioseleccionado !== null ? (
              <>
                <NavItem
                  className={bem.e("nav-item")}
                  onClick={handleClick("Inventario")}
                >
                  <BSNavLink
                    className={bem.e("nav-item-collapse")}
                    onClick={() => resetDasboard()}
                  >
                    <div className="d-flex">
                      <MdSend className={bem.e("nav-item-icon")} />
                      <span className=" align-self-start font-weight-bold">
                        Inventario
                      </span>
                    </div>
                    <MdKeyboardArrowDown
                      className={bem.e("nav-item-icon")}
                      style={{
                        padding: 0,
                        transform: isOpenS.isOpenInventario
                          ? "rotate(0deg)"
                          : "rotate(-90deg)",
                        transitionDuration: "0.3s",
                        transitionProperty: "transform",
                      }}
                    />
                  </BSNavLink>
                </NavItem>
                {casoUno || casoTres ? (
                  <Collapse isOpen={isOpenS.isOpenInventario}>
                    {subMenuInventario.map(
                      ({ to, name, exact, Icon }, index) => (
                        <NavItem key={index} className={bem.e("nav-item")}>
                          <BSNavLink
                            id={`navItem-${name}-${index}`}
                            className="font-italic"
                            tag={NavLink}
                            to={to}
                            activeClassName="active"
                            exact={exact}
                            onClick={() => resetDasboard()}
                          >
                            <Icon className={bem.e("nav-item-icon")} />
                            <span className="">{name}</span>
                          </BSNavLink>
                        </NavItem>
                      )
                    )}
                  </Collapse>
                ) : casoDos ? (
                  <Collapse isOpen={isOpenS.isOpenInventario}>
                    <NavItem className={bem.e("nav-item")}>
                      <BSNavLink
                        id={`navItem-${subMenuInventario[0].name}`}
                        className="font-italic"
                        tag={NavLink}
                        to={subMenuInventario[0].to}
                        activeClassName="active"
                        exact={subMenuInventario[0].exact}
                        onClick={() => resetDasboard()}
                      >
                        <BsFillInboxesFill className={bem.e("nav-item-icon")} />
                        <span className="">{subMenuInventario[0].name}</span>
                      </BSNavLink>
                    </NavItem>
                  </Collapse>
                ) : null}
              </>
            ) : null}
            {/* -------------FIN MENUS DEL SUPER ADMINISTRADOR ------------------ */}

            {/* -------------INICIO MENU CUENTA-------------------- */}
            <NavItem
              className={bem.e("nav-item")}
              onClick={handleClick("Pages")}
            >
              {/* INICIO MENÚ CUENTA */}
              <BSNavLink
                className={bem.e("nav-item-collapse")}
                onClick={() => resetDasboard()}
              >
                <div className="d-flex">
                  <MdSend className={bem.e("nav-item-icon")} />
                  <span className="font-weight-bold">CUENTA</span>
                </div>
                <MdKeyboardArrowDown
                  className={bem.e("nav-item-icon")}
                  style={{
                    padding: 0,
                    transform: isOpenS.isOpenPages
                      ? "rotate(0deg)"
                      : "rotate(-90deg)",
                    transitionDuration: "0.3s",
                    transitionProperty: "transform",
                  }}
                />
              </BSNavLink>
              {/* FIN MENÚ CUENTA */}
            </NavItem>

            <Collapse isOpen={isOpenS.isOpenPages}>
              {subMenuCerrarSesion.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item")}>
                  {name === "Cerrar Sesión" ? (
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="font-italic"
                      tag={NavLink}
                      to={to}
                      activeClassName=""
                      exact={exact}
                      onClick={() => {
                        terminarSesion();
                        resetDasboard();
                      }}
                    >
                      <Icon className={bem.e("nav-item-icon")} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  ) : (
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="font-italic"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e("nav-item-icon")} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  )}
                </NavItem>
              ))}
            </Collapse>
          </Nav>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
